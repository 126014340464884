import axiosSource from 'axios';

export const axios = axiosSource.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

// axios.interceptors.response.use(response => {
//   return response;
// }, error => {
//   return Promise.reject(error.response);
// });