export const defaultState = () => ({
  mapCoordinates: {},
  zoom: null,
  showSettingsModal: false,
  showActivePostmenListModal: false,
  showShiftPointsTable: false,
  autoRefresh: false,
  autoRefreshTime: null,
  polygons: [],
  polygonsCancelToken: null,
  selectedLayer: 'auto',
  dateTimeForActivePostmen: { start: null, end: null },
  activePostmen: [],
  showActivePostmen: false,
  groupMarkers: true,
  showShiftLines: true,
  playLinesStatus: 'stop', // stop pause play
  playLinesStep: 0,
  showCoverage: true,
  shiftPostman: [],
  shiftPostmanScans: [],
  shiftPostmanAll: [],
  loading: false,
  errors: null,
})

export default {
  ...defaultState()
};