<template>
  <modal @closed="handleClose" adaptive :max-width="900" :max-height="450" name="poly-info">
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="info-bloc text-center">
          <h2 class="text-3xl font-bold">{{ poly.name }}</h2>
          <ul>
            <li>color: {{ poly.color }}</li>
            <li v-if="poly.jpt_code">JPT code: {{ poly.jpt_code }}</li>
          </ul>
        </div>
      </div>
      <div class="text-right p-5">
        <button class="p-2 px-6 bg-orange-500 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "PolygonInfoModal",
  props: {
    showModal: {
      type: Boolean,
    },
    poly: {
      type: Object,
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    }
  },
  methods: {
    show () {
      this.$modal.show('poly-info');
    },
    hide () {
      this.$modal.hide('poly-info');
    },
    handleClose() {
      this.$emit('close-modal');
    },
  },
}
</script>

<style scoped>
*:focus {outline:none !important}

li {
  margin-top: 10px;
}
</style>