<template>
  <div class="map-page">
    <div class="content">

      <AddEditPointModal
          :point-for-edit="pointForEdit"
          :show-modal="showAddEditPointModal"
          :picked-coordinate="pickedCoordinate"
          @add-point="addNewPoint"
          @edit-point="editPoint"
          @delete-point="deletePoint"
          @close-modal="handleCloseNewEditPointModal"
      />

      <MapComponent
          :polygons="polygons"
          :active-postmen="activePostmen"
          :shift-postman="shiftPostman"
          :shift-postman-scans="shiftPostmanScans"
          :shift-postman-all="shiftPostmanAll"
          :show-active-postmen="showActivePostmen"
          :group-markers="groupMarkers"
          :show-shift-lines="showShiftLines"
          :show-coverage="showCoverage"
          :play-lines-status="playLinesStatus"
          :play-lines-step="playLinesStep"
          :loading="loading"
          :show-points-table="showShiftPointsTable"
          :own-points="ownPoints"
          @change-bounds="handleChangeBounds"
          @change-zoom="handleResize"
          @select-shift-point="handleSelectShiftPoint"
          @select-active-postman="handleSelectActivePostman"
          @change-play-lines-step="changePlayLinesStep"
          ref="map"
          v-on:callMethodInParent="openModalWindow"
      />


      <PolygonInfoModal
          :poly="selectedPoly"
          :show-modal="showInfoModal"
          @close-modal="handleCloseInfoModal"
      />
      <AppSettingsModal
          :show-modal="showSettingsModal"
          @close-modal="handleCloseSettingsModal"
      />
      <ShiftPointInfoModal
          :point="selectedShiftPoint"
          :show-modal="showShiftPointInfoModal"
          :number-of-points="shiftPostmanAll.length"
          @close-modal="handleCloseShiftPointInfoModal"
      />
      <ActivePostmanInfoModal
          :postman="selectedActivePostman"
          :show-modal="showActivePostmanInfoModal"
          @close-modal="handleCloseActivePostmanInfoModal"
      />
      <ListOfActivePostmenModal
          :postmen="activePostmen"
          :show-modal="showActivePostmenListModal"
          @close-modal="handleCloseActivePostmenListModal"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { createNamespacedHelpers } from 'vuex';

import {
  CHANGE_PLAY_LINES_STEP,
  FETCH_ACTIVE_POSTMEN,
  FETCH_POLYGONS,
  FETCH_SHIFT_POSTMAN,
  INIT_SETTINGS
} from "@/store/modules/map/actions";
import MapComponent from "@/components/MapComponent";
import AppSettingsModal from "@/components/AppSettingsModal";
import {
  SET_AUTO_REFRESH_TIME,
  SET_MAP_COORDINATES,
  SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL,
  SET_SHOW_SETTINGS_MODAL,
  SET_ZOOM
} from "@/store/modules/map/mutations";
import ShiftPointInfoModal from "@/components/ShiftPointInfoModal";
import ActivePostmanInfoModal from "@/components/ActivePostmanInfoModal";
import ListOfActivePostmenModal from "@/components/ListOfActivePostmenModal";
import PolygonInfoModal from "@/components/PolygonInfoModal";
import {notify} from "@/utils/notifications";
import {
  addNewPoint,
  editPoint,
  getPoints,
  deletePoint,
} from "@/api";
import AddEditPointModal from "@/components/AddEditPointModal";

const { mapActions, mapState, mapMutations } = createNamespacedHelpers('map');

export default {
  name: "Map",
  components: {ListOfActivePostmenModal, ShiftPointInfoModal, AppSettingsModal, MapComponent, ActivePostmanInfoModal, PolygonInfoModal, AddEditPointModal},
  data: () => ({
    map: undefined,
    selectedPoly: {},
    showInfoModal: false,
    queryParams: {},
    selectedShiftPoint: {},
    selectedActivePostman: {},
    showShiftPointInfoModal: false,
    showActivePostmanInfoModal: false,
    pointForEdit: {},
    showAddEditPointModal: false,
    pickedCoordinate: {},
    loadingActive: true,
    ownPoints: [],
  }),
  computed: {
    ...mapState({
      showSettingsModal: state => state.showSettingsModal,
      showActivePostmenListModal: state => state.showActivePostmenListModal,
      polygons: state => state.polygons,
      activePostmen: state => state.activePostmen,
      showActivePostmen: state => state.showActivePostmen,
      shiftPostman: state => state.shiftPostman,
      shiftPostmanScans: state => state.shiftPostmanScans,
      shiftPostmanAll: state => state.shiftPostmanAll,
      loading: state => state.loading,
      groupMarkers: state => state.groupMarkers,
      showShiftLines: state => state.showShiftLines,
      showCoverage: state => state.showCoverage,
      selectedLayer: state => state.selectedLayer,
      autoRefresh: state => state.autoRefresh,
      autoRefreshTime: state => state.autoRefreshTime,
      dateTimeForActivePostmen: state => state.dateTimeForActivePostmen,
      playLinesStatus: state => state.playLinesStatus,
      playLinesStep: state => state.playLinesStep,
      showShiftPointsTable: state => state.showShiftPointsTable,
    })
  },
  async mounted() {
    this.initSettings();


    this.loadingActive = true;
    try {
      const results = await getPoints();
      if(results) {
        this.ownPoints = results.data;
      }
      this.loadingActive = false;

    } catch (e) {
      console.error(e);
      this.handleRequestError(e)
    }
  },
  watch: {
    autoRefreshTime(time) {
      const { key, date, shiftId, refresh, newFormat } = this.queryParams;
      if (time > 0 || (!refresh && !this.autoRefresh) || (refresh && !JSON.parse(refresh))) return;
      if (key && date) {
        this.fetchShiftPostman({ key, date, shiftId, newFormat });
      } else {
        if (this.showActivePostmen && !this.dateTimeForActivePostmen.start) {
          this.fetchActivePostmen();
        }
      }
      this.setAutoRefreshTime(60);
    },
  },
  methods: {
    ...mapActions({
      fetchPolygons: FETCH_POLYGONS,
      fetchActivePostmen: FETCH_ACTIVE_POSTMEN,
      fetchShiftPostman: FETCH_SHIFT_POSTMAN,
      initSettings: INIT_SETTINGS,
      changePlayLinesStep: CHANGE_PLAY_LINES_STEP
    }),
    ...mapMutations({
      setCoordinates: SET_MAP_COORDINATES,
      setZoom: SET_ZOOM,
      setShowSettingsModal: SET_SHOW_SETTINGS_MODAL,
      setShowActivePostmenListModal: SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL,
      setAutoRefreshTime: SET_AUTO_REFRESH_TIME,
    }),
    handleResize(zoom) { // zoom from 0 to 21 (max for our app - 20)
      this.setZoom(zoom);
    },
    handleChangeBounds: debounce (async function (value) {
      if (value) {
        const southWest = value.getSouthWest();
        const northEast = value.getNorthEast();
        this.setCoordinates({southWest, northEast})
        if (this.selectedLayer !== 'none') {
          this.fetchPolygons();
        }
      }
    }, 400),
    handleSelectPoly(poly) {
      this.selectedPoly = poly.feature.j;
      this.showInfoModal = true;
    },
    handleSelectShiftPoint(point) {
      this.selectedShiftPoint = point;
      this.showShiftPointInfoModal = true;
    },
    handleSelectActivePostman(postman) {
      this.selectedActivePostman = postman;
      this.showActivePostmanInfoModal = true;
    },
    handleCloseInfoModal() {
      this.showInfoModal = false;
    },
    handleCloseShiftPointInfoModal() {
      this.showShiftPointInfoModal = false;
    },
    handleCloseActivePostmanInfoModal() {
      this.showActivePostmanInfoModal = false;
    },
    handleCloseSettingsModal() {
      this.setShowSettingsModal(false);
    },
    handleCloseActivePostmenListModal() {
      this.setShowActivePostmenListModal(false);
    },


    async addNewPoint(pointData) {
      try {
        const {data: point} = await addNewPoint({...pointData, ...this.newPointCoordinates});

        this.ownPoints.push(point);
        notify({
          type: 'success',
          duration: 5000,
          title: 'Sukces',
          text: 'Punkt został pomyślnie dodany'
        });
        //this.filterOwnPoints();
      } catch (e) {
        console.error(e)
        this.handleRequestError(e)
      }
      this.handleCloseNewEditPointModal();
    },
    async editPoint(pointData) {
      try {
        await editPoint({...pointData})
        notify({
          type: 'success',
          duration: 5000,
          title: 'Sukces',
          text: 'Punkt został pomyślnie edytowany'
        });
        const indexOfEdited = this.ownPoints.findIndex(point => point.id === pointData.id);
        this.ownPoints[indexOfEdited] = {...pointData};
        //this.filterOwnPoints();
      } catch (e) {
        console.error(e)
        this.handleRequestError(e)
      }
      this.handleCloseNewEditPointModal();
    },

    async deletePoint(pointData) {
      try {
        await deletePoint({...pointData})
        notify({
          type: 'success',
          duration: 5000,
          title: 'Sukces',
          text: 'Punkt został pomyślnie usunięty'
        });
        this.ownPoints = this.ownPoints.filter(point => point.id !== pointData.id);
        //this.filterOwnPoints();

      } catch (e) {
        console.error(e)
        this.handleRequestError(e)
      }
      this.handleCloseNewEditPointModal();
    },

    handleCloseNewEditPointModal() {
      this.showAddEditPointModal = false;
      this.pointForEdit = {};
    },
    openModalWindow(pickedCoordinate){
      this.pickedCoordinate = pickedCoordinate;
      this.showAddEditPointModal = true;
    }

  },

}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 0px);
}
</style>

<style>
.close-btn {
  border-color: #f83a3a;
  color: #fff;
  background-image: -webkit-linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-image: linear-gradient(45deg, #f83a3a 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 400ms ease-in-out;
  transition: background 400ms ease-in-out;
}
.close-btn:hover {
  background-position: 0;
}

.vm--modal {
  border-radius: 20px!important;
  border: 3px solid #4F9AFB;
}

*:focus {
  outline: none !important
}
</style>