import { axios as axiosInstance } from "@/services/axiosInstance";

let debug = false; // remove
let forceLevel = 'sub_rejon_id';

if(debug){
    forceLevel = 'main_hub_id';
}
// Map
export const getPolygons = async ({ southWest, northEast, zoom, cancelToken }) =>

    axiosInstance.get(`/polygon/shape?southWestPoint=${[southWest.lat(), southWest.lng()]}&northEastPoint=${[northEast.lat(), northEast.lng()]}&zoom=${zoom}&forceLevel=${forceLevel}`, {
        cancelToken: cancelToken
    }).catch(function (thrown) {
        throw new Error(thrown);
    });

export const getActivePostmen = ({ start, end }) => axiosInstance.get(`/map/active${start ? '?start='+start : ''}${end ? '&end='+end : ''}`);

export const getShiftPostman = ({ key, date, shiftId, newFormat }) =>
    axiosInstance.get(`/map/postman?key=${key}&date=${date}${shiftId ? '&shiftId='+shiftId : ''}${newFormat ? '&newFormat='+newFormat : ''}`);


export const getPoints = async () => axiosInstance.get('/polygon/');
export const addNewPoint = async (data) => axiosInstance.post('/polygon/add', data);
export const editPoint = async (data) => axiosInstance.post('/polygon/edit', data, {headers: {'Content-Type': 'multipart/form-data'}})
export const deletePoint = async (data) => axiosInstance.post('/polygon/delete', data, {headers: {'Content-Type': 'multipart/form-data'}})

