import axios from "axios";
import {getPolygons, getShiftPostman} from "@/api";
import {notify} from "@/utils/notifications";
import {
  SET_POLYGONS,
  SET_LOADING,
  SET_ERRORS,
  SET_ACTIVE_POSTMEN,
  SET_SHIFT_POSTMAN,
  SET_POLYGONS_CANCEL_TOKEN,
  SET_SHOW_ACTIVE_POSTMEN,
  SET_GROUP_MARKERS,
  SET_SHOW_SHIFT_LINES,
  SET_SHOW_COVERAGE,
  SET_SELECTED_LAYER,
  SET_AUTO_REFRESH,
  SET_DATE_TIME_FOR_ACTIVE_POSTMEN,
  SET_PLAY_LINES_STEP,
  SET_PLAY_LINES_STATUS
} from "@/store/modules/map/mutations";

export const INIT_SETTINGS = 'INIT_SETTINGS';
export const FETCH_POLYGONS = 'FETCH_POLYGONS';
export const FETCH_ACTIVE_POSTMEN = 'FETCH_ACTIVE_POSTMEN';
export const CHANGE_SHOW_ACTIVE_POSTMEN = 'CHANGE_SHOW_ACTIVE_POSTMEN';
export const FETCH_SHIFT_POSTMAN = 'FETCH_SHIFT_POSTMEN';
export const CHANGE_SELECTED_LAYER = 'CHANGE_SELECTED_LAYER';
export const CHANGE_DATE_TIME_FOR_ACTIVE_POSTMEN = 'CHANGE_DATE_TIME_FOR_ACTIVE_POSTMEN';
export const CHANGE_PLAY_LINES_STEP = 'CHANGE_PLAY_LINES_STEP';

export default {
  async [FETCH_POLYGONS]({commit, state}) {
    let cancelTokenSource;
    if (state.polygonsCancelToken) {
      state.polygonsCancelToken.cancel();
    }
    const CancelToken = axios.CancelToken;
    cancelTokenSource = CancelToken.source();
    commit(SET_POLYGONS_CANCEL_TOKEN, cancelTokenSource);

    commit(SET_LOADING, true);
    try {
      const res = await getPolygons({
        southWest: state.mapCoordinates.southWest,
        northEast: state.mapCoordinates.northEast,
        zoom: state.zoom,
        forceLevel: state.selectedLayer,
        cancelToken: cancelTokenSource ? cancelTokenSource.token : null
      });
      commit(SET_POLYGONS, res.data);
      commit(SET_ERRORS, null);
    } catch (error) {
      commit(SET_ERRORS, error);
      if (error.message !== "Cancel") {
        notify({
          type: 'error',
          title: 'Błąd pobierania wielokątów',
          text: error.message
        });
      }
    } finally {
      commit(SET_LOADING, false);
    }
  },
  // async [FETCH_ACTIVE_POSTMEN]({commit, state}) {
  //   commit(SET_LOADING, true);
  //
  //   try {
  //     let res = [];
  //     if (state.dateTimeForActivePostmen && state.dateTimeForActivePostmen.start) {
  //       res = await getActivePostmen({start: state.dateTimeForActivePostmen.start.toJSON(), end: state.dateTimeForActivePostmen.end.toJSON()});
  //     } else {
  //       res = await getActivePostmen({ start: null, end: null });
  //     }
  //     commit(SET_ACTIVE_POSTMEN, res.data);
  //     commit(SET_ERRORS, null);
  //   } catch (error) {
  //     commit(SET_ERRORS, error);
  //     notify({
  //       type: 'error',
  //       title: 'Błąd pobierania aktywnych listonosza',
  //       text: error.message
  //     });
  //   } finally {
  //     commit(SET_LOADING, false);
  //   }
  // },
  async [FETCH_SHIFT_POSTMAN]({commit}, {key, date, shiftId, newFormat}) {
    commit(SET_LOADING, true);

    try {
      const res = await getShiftPostman({key, date, shiftId, newFormat});
      commit(SET_SHIFT_POSTMAN, res.data);
      let postman_id = '';
      if (res.data.route && res.data.route[0].postman_id) {
        postman_id = res.data.route[0].postman_id;
      }
      else {
        postman_id = res.data[0].postman_id;
      }
      notify({
        type: 'success',
        duration: 7000,
        title: 'Sukces',
        text: `Punkty zmiany dla listonosza id: ${postman_id} zostały pomyślnie załadowane`
      });
      commit(SET_ERRORS, null);
    } catch (error) {
      commit(SET_ERRORS, error);
      notify({
        type: 'error',
        title: 'Pobierz zmianę błędu listonosza',
        text: error.message
      });
    } finally {
      commit(SET_LOADING, false);
    }
  },
  [CHANGE_SHOW_ACTIVE_POSTMEN]({commit, dispatch}, status) {
    commit(SET_SHOW_ACTIVE_POSTMEN, status);
    if (status) {
      dispatch(FETCH_ACTIVE_POSTMEN);
    } else {
      commit(SET_ACTIVE_POSTMEN, [])
    }
  },
  [CHANGE_SELECTED_LAYER]({commit, dispatch}, layer) {
    commit(SET_SELECTED_LAYER, layer);
    if (layer === 'none') {
      commit(SET_POLYGONS, []);
    } else {
      dispatch(FETCH_POLYGONS);
    }
  },
  [CHANGE_DATE_TIME_FOR_ACTIVE_POSTMEN]({commit, dispatch, state}, dateTime) {
    if (dateTime !== state.dateTimeForActivePostmen) {
      commit(SET_DATE_TIME_FOR_ACTIVE_POSTMEN, dateTime);
      dispatch(FETCH_ACTIVE_POSTMEN);
    }
  },
  [CHANGE_PLAY_LINES_STEP] ({commit, state}, step) {
    if (step >= 0) {
      if (step > state.shiftPostmanAll.length) {
        commit(SET_PLAY_LINES_STATUS, 'stop');
        commit(SET_PLAY_LINES_STEP, 0);
      } else {
        commit(SET_PLAY_LINES_STEP, step);
      }
    }
  },
  [INIT_SETTINGS]({commit}) {
    const itemsToInit = ['showActivePostmen', 'groupMarkers', 'showShiftLines', 'showCoverage', 'autoRefresh'];
    const commits = [SET_SHOW_ACTIVE_POSTMEN, SET_GROUP_MARKERS, SET_SHOW_SHIFT_LINES, SET_SHOW_COVERAGE, SET_AUTO_REFRESH];

    itemsToInit.forEach((itemName, index) => {
      if (localStorage.getItem(itemName) !== null) {
        commit(commits[index], !!JSON.parse(localStorage.getItem(itemName)));
      }
    });

    if (localStorage.getItem('selectedLayer')) commit(SET_SELECTED_LAYER, localStorage.getItem('selectedLayer'));
  }
}