<template>
  <modal @closed="handleClose" adaptive :max-width="900" :height="275" :max-height="400" name="active-postman-info-modal">
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="text-center">
          <ul class="text-xl">
            <li>Listonosz id: <b>{{ postman.postman_id }}</b></li>
            <li>Data: <b>{{ postman.datetime }}</b></li>
            <li>
              <a class="font-bold bg-blue-400 text-white p-3 rounded-xl" target="_blank" rel="noopener noreferrer" :href="shiftLink">Pokaż zmianę</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-right p-5">
        <button class="p-2 px-6 bg-orange-500 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ActivePostmanInfoModal",
  props: {
    showModal: {
      type: Boolean,
    },
    postman: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shiftLink() {
      return `${process.env.VUE_APP_MAP_URL}/?key=${this.postman?.postman?.key}&date=${this.postman.date}&shiftId=${this.postman.shift_id}`;
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
  },
  methods: {
    show () {
      this.$modal.show('active-postman-info-modal');
    },
    hide () {
      this.$modal.hide('active-postman-info-modal');
    },
    handleClose() {
      this.$emit('close-modal');
    },
  },
}
</script>

<style scoped>
li {
  margin-top: 15px;
}
</style>