<template>
  <modal
      @closed="handleClose"
      :clickToClose="true"
      adaptive
      :max-width="900"
      :height="400"
      :max-height="400"
      name="add-new-point-modal"
      class="add-new-point-modal"
  >
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="text-center">
          <form @submit.prevent="onFormSubmit">
            <ul class="text-xl">
              <li><b>Status:</b>
                <select v-model="pointValues.status" class="text-black p-2 mr-3 ml-3 rounded-lg" required>
                  <option
                      v-for="status in statuses"
                      :key = "status.id"
                      :value="status.value"
                      :selected="pointValues.status === status.value"
                  >
                    {{ status.value }}
                  </option>
                </select>
              </li>
              <li>

                <p class="mb-2 font-bold">Description: </p>
                <textarea v-model="pointValues.address" class="border rounded desc p-2" rows="3" />
              </li>
            </ul>
            <button v-if="pointValues.id" type="submit" name="delete" style="background-color: #ee1818; float: left" class="mt-2 p-2 px-6 rounded-xl text-white text-xl font-bold add-btn">Delete</button>
            <button style="float: right" type="submit" class="mt-2 p-2 px-6 rounded-xl text-white text-xl font-bold add-btn">{{pointForEdit.id ? 'Save' : 'Add'}}</button>
          </form>
        </div>
      </div>
      <div class="text-right p-5">
        <button class="p-2 px-6 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
import {statuses} from "@/utils/constants";

export default {
  name: "AddEditPointModal",
  data: () => ({
    statuses: statuses,
    isDelete: false,
    pointValues: {
      status: '',
      address: '',
      latitude: '',
      longitude: '',
    }
  }),
  props: {
    showModal: {
      type: Boolean,
    },
    pointForEdit: {
      type: Object,
    },
    pickedCoordinate: {
      type: Object,
    }
  },
  watch: {
    showModal: {
      deep: true,
      handler(value) {
        value ? this.show() : this.hide();
      }
    },
    pointForEdit: {
      deep: true,
      handler(point) {

      if (point.id) {
        this.pointValues = {...this.pointValues, ...point};
      }
    }},
    pickedCoordinate: {
      deep: true,
      handler(coordinate) {
        this.pointValues.latitude = coordinate.latitude;
        this.pointValues.longitude = coordinate.longitude;

        this.pointForEdit = coordinate;
      }},
  },
  methods: {
    show () {
      this.$modal.show('add-new-point-modal');
    },
    hide () {
      this.$modal.hide('add-new-point-modal');
    },
    handleClose() {
      this.$emit('close-modal');
      this.pointValues.status = '';
      this.pointValues.address = '';
      this.pointValues.latitude = '';
      this.pointValues.longitude = '';
    },
    onFormSubmit(e) {
      console.log(e.submitter.name);

      if(e.submitter.name === 'delete') {
        this.$emit('delete-point', this.pointValues)
        return;
      }

      if (this.pointForEdit.id) {
        this.$emit('edit-point', this.pointValues)
      } else {
        this.$emit('add-point', this.pointValues)
      }
    },
  },
}
</script>

<style scoped>
li {
  font-size: 1.15rem;
  margin-top: 15px;
}

.add-btn {
  background-color: forestgreen;
}

.desc {
  border-color: black;
  width: 350px;
}

@media screen and (max-width: 600px) {
  .desc {
    width: 300px;
  }
}
</style>
