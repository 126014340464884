var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-map relative"},[_c('div',{staticClass:"absolute search-input"},[_c('gmap-autocomplete',{attrs:{"placeholder":"Szukaj...","options":{fields: ['geometry', 'address_components'], strictBounds: true},"select-first-on-enter":true},on:{"place_changed":_vm.setPlace}}),_c('div',{staticClass:"edit-mode"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editModeEnabled),expression:"editModeEnabled"}],attrs:{"id":"editmode","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.editModeEnabled)?_vm._i(_vm.editModeEnabled,null)>-1:(_vm.editModeEnabled)},on:{"change":function($event){var $$a=_vm.editModeEnabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.editModeEnabled=$$a.concat([$$v]))}else{$$i>-1&&(_vm.editModeEnabled=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.editModeEnabled=$$c}}}}),_c('label',{attrs:{"for":"editmode"}},[_vm._v("Edit mode")])])],1),_c('GmapMap',{ref:"gmap",staticClass:"map-class",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":_vm.mapOptions,"map-type-id":"terrain"},on:{"zoom_changed":_vm.handleChangeZoom,"bounds_changed":_vm.handleChangeBounds,"click":_vm.sayHello}},[_vm._l((_vm.ownPoints),function(point){return _c('GmapMarker',{key:point.id,attrs:{"icon":{
                  url: point.icon ?  point.icon : 'http://maps.google.com/mapfiles/kml/paddle/red-circle.png',
                  size: {width: 25, height: 25, f: 'px', b: 'px'},
                  scaledSize: {width: 25, height: 25, f: 'px', b: 'px'},
                },"position":{lat: Number(point.latitude), lng: Number(point.longitude)}},on:{"click":function($event){return _vm.sayHello(point)},"mouseover":function($event){return _vm.handleHoverPostman(point)},"mouseout":function($event){return _vm.handleHoverPostman(null)}}})}),(_vm.mapLoaded)?[(this.place)?_c('Gmap-Marker',{attrs:{"position":{
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      }}}):_vm._e(),_c('gmap-cluster',{attrs:{"max-zoom":_vm.groupMarkers ? 19 : 0}},_vm._l((_vm.showActivePostmen ? _vm.activePostmen : []),function(postman){return _c('GmapMarker',{key:postman.postman_id,attrs:{"position":{lat: Number(postman.latitude), lng: Number(postman.longitude)},"icon":{
          url: 'http://maps.google.com/mapfiles/ms/icons/police.png',
          size: {width: 43, height: 43, f: 'px', b: 'px'},
          scaledSize: {width: 43, height: 43, f: 'px', b: 'px'}
        }},on:{"mouseover":function($event){return _vm.handleHoverPostman(postman)},"mouseout":function($event){return _vm.handleHoverPostman(null)},"click":function($event){return _vm.handleClickActivePostman(postman)}}})}),1),(!_vm.showPointsTable)?[_c('gmap-cluster',{attrs:{"max-zoom":_vm.groupMarkers && !_vm.playPauseStatus  ? 19 : 0}},_vm._l((_vm.localShiftPostman),function(point,index){return _c('GmapMarker',{key:index,attrs:{"position":{lat: Number(point.latitude), lng: Number(point.longitude)},"clickable":true,"icon":point.type === 'scan' ?  ({url: 'http://maps.google.com/mapfiles/kml/shapes/post_office.png'}): ''},on:{"mouseover":function($event){return _vm.handleHoverShiftPoint(point, index)},"mouseout":function($event){return _vm.handleHoverShiftPoint(null)},"click":function($event){return _vm.handleClickShiftPoint(point, index)}}})}),1),((_vm.localShiftPostman && _vm.localShiftPostman.length && _vm.showShiftLines) || _vm.playPauseStatus)?_c('GmapPolyline',{attrs:{"path":_vm.polyLinePath,"options":_vm.polyLineOptions}}):_vm._e()]:_vm._e(),(_vm.showPointsTable)?[_vm._l((_vm.ShiftPostmanFromTable),function(point,index){return _c('GmapMarker',{key:index,attrs:{"position":{lat: Number(point.latitude), lng: Number(point.longitude)},"icon":point.type === 'scan' ?  ({url: 'http://maps.google.com/mapfiles/kml/shapes/post_office.png'}): ''}})}),_c('GmapPolyline',{attrs:{"path":_vm.polyLinePathFromTable,"options":_vm.polyLineOptions}})]:_vm._e()]:_vm._e()],2),(_vm.showPointsTable)?_c('ShiftPointsTable',{attrs:{"shift-points":_vm.shiftPostmanAll},on:{"hover-point":_vm.handleHoveredPointFromTable}}):_vm._e(),(_vm.hoveredPoly || _vm.hoveredPostman || _vm.hoveredShiftPoint || _vm.playPauseStatus)?_c('div',{class:[
        'hovered-poly',
        'rounded-r-xl',
        {'border-blue-500' : _vm.hoveredPostman && !_vm.hoveredShiftPoint, 'border-red-500': _vm.hoveredShiftPoint || _vm.playPauseStatus},
        'absolute',
        'p-2',
        {'pt-0': !_vm.hoveredShiftPoint && !_vm.hoveredPostman && _vm.hoveredPoly && _vm.hoveredPoly.layer && !_vm.playPauseStatus}
      ],style:({borderColor: (!_vm.hoveredPostman && !_vm.hoveredShiftPoint && !_vm.playPauseStatus) ? (_vm.showCoverage ? _vm.getColorForPercentage(_vm.hoveredPoly.coverage) : _vm.hoveredPoly.color) : ''})},[_c('div',{staticClass:"text-2xl"},[(_vm.playPauseStatus)?[_c('b',{staticClass:"rounded text-red-700"},[_vm._v(_vm._s(_vm.playLinesStep + 1)+"/"+_vm._s(_vm.shiftPostmanAll.length))]),_vm._v(" Listonosz id: "),_c('b',[_vm._v(_vm._s(this.shiftPostmanAll[_vm.playLinesStep].postman_id))]),_vm._v(" Data: "),_c('b',[_vm._v(_vm._s(this.shiftPostmanAll[_vm.playLinesStep].datetime))])]:(_vm.hoveredShiftPoint)?[_c('b',{staticClass:"rounded text-red-700"},[_vm._v(_vm._s(_vm.hoveredShiftPoint.index)+"/"+_vm._s(_vm.shiftPostmanAll.length))]),_vm._v(" Listonosz id: "),_c('b',[_vm._v(_vm._s(_vm.hoveredShiftPoint.postman_id))]),_vm._v(" Data: "),_c('b',[_vm._v(_vm._s(_vm.hoveredShiftPoint.datetime))])]:(_vm.hoveredPostman)?[_vm._v(" "+_vm._s(_vm.hoveredPostman.status)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.hoveredPostman.address)+" ")]:[(_vm.hoveredPoly.layer)?[_c('span',{staticClass:"text-sm pt-0 pb-0"},[_vm._v(_vm._s(_vm.hoveredPoly.name.substring(0, _vm.hoveredPoly.name.indexOf('{')))+" | ")]),_c('span',{staticClass:"text-sm pt-0 pb-0"},[_vm._v("Punkty log [0-100]: "+_vm._s(_vm.hoveredPoly.coverage))]),_c('br')]:_vm._e(),(_vm.hoveredPoly.parent)?_c('p',{staticClass:"text-sm pt-0 pb-0"},[_vm._v(_vm._s(_vm.hoveredPoly.parent))]):_vm._e()]],2)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loader-wrapper absolute"},[_vm._m(0)]):_vm._e(),(_vm.totalItemsInSelectedArea )?_c('div',[_c('div',{staticClass:"text-2xl",staticStyle:{"position":"absolute","top":"100px","background":"blue","color":"white","width":"500px","z-index":"999"}},[_vm._v(" Przesyłek w zaznaczonych ("+_vm._s(_vm.selecetedPolygonsNo)+") rejonach: "+_vm._s(_vm.totalItemsInSelectedArea)+" ")])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader-inner rounded-t-lg flex flex-row items-center p-2 justify-center"},[_c('div',{staticClass:"text-white font-bold text-xl mr-2"},[_vm._v("Ładowanie")]),_c('div',{staticClass:"loader"})])}]

export { render, staticRenderFns }