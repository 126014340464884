<template>
  <div id="app">
    <router-view/>
    <notifications style="padding-top: 15px;" :max="3" position="top center" group="notify" />
  </div>
</template>

<script>


export default {
  name: 'App',

}
</script>

<style>
body {
  font-family: 'Rubik', serif;
}

.vc-time .vc-select:last-child {
  display: none;
}

.vc-time > span {
  display: none;
}
</style>
