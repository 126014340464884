import Vue from 'vue';
import App from './App.vue';
import Notifications from 'vue-notification'
import router from './router'
import store from './store';
import * as VueGoogleMaps from 'vue2-google-maps';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog, faArrowLeft, faArrowRight, faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ToggleButton from 'vue-js-toggle-button';
import '@/assets/css/tailwind.css';
import VModal from 'vue-js-modal';
import VCalendar from 'v-calendar';

library.add(faCog, faArrowLeft, faArrowRight, faPause, faPlay);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(Notifications);
Vue.use(ToggleButton);
Vue.use(VCalendar);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjMwIj5r41wAdLuR4NdIuk-rFpCwBM41E',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },
});

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
