import { parse } from 'wkt';

export const geoJsonFromWKT = (wktArray) => {
    const arrayOfFeatures = wktArray.map((polygon) => {
            return ({
                type: "Feature",
                geometry: parse(polygon['geometry']),
                properties: {
                    color: polygon.color || '#606060',
                    jpt_code: polygon.jpt_code,
                    id: polygon.id,
                    name: polygon.name,
                    layer: polygon.layer,
                    coverage: polygon.coverage,
                    parent: polygon.parent,
                },
            })
    })

    return ({type: "FeatureCollection", features: arrayOfFeatures});
};

export const getColorForPercentage = (percentage) => {

    let heatmap = [
        '#e93e3a',
        '#ed683c',
        '#f3903f',
        '#fdc70c',
        '#fff33b',
        '#ffde3b',
        '#94f600',
        '#3bff69',
        '#3bff79',
        '#00de2b',
    ]




    if(percentage < 50)
    {
        return heatmap[0];
    }

    if(percentage >= 50 && percentage < 55)
    {
        return heatmap[1];
    }

    if(percentage >= 55 && percentage < 60)
    {
        return heatmap[2];
    }

    if(percentage >= 60 && percentage < 65)
    {
        return heatmap[3];
    }

    if(percentage >= 65 && percentage < 70)
    {
        return heatmap[4];
    }

    if(percentage >= 70 && percentage < 75)
    {
        return heatmap[5];
    }

    if(percentage >= 75 && percentage < 80)
    {
        return heatmap[6];
    }

    if(percentage >= 80 && percentage < 85)
    {
        return heatmap[7];
    }

    if(percentage >= 85 && percentage < 90)
    {
        return heatmap[8];
    }

    return heatmap[9];


    // const percent = percentage / 100;
    //
    // let red = 255;
    // let green = 255;
    // if (percent >= 0 && percent <= 0.5) {
    //     green = 510 * percent;
    // } else if (percent > 0.5 && percent <= 1) {
    //     red = -510 * percent + 510;
    // }
    //
    // return 'rgb(' + [red, green, 0].join(',') + ')';
}