<template>
  <modal @closed="handleClose" adaptive :max-width="650" :height="'auto'" class="settings-modal" name="settings">
    <div class="flex flex-col justify-between h-full custom-modal">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="info-bloc text-center">
          <h2 class="text-3xl font-bold mb-3">Ustawienia</h2>
          <ul>

            <li>
              <span class="text-black text-lg mr-3">Pokaż pokrycie</span>
              <toggle-button
                  :value="showCoverage"
                  @change="handleShowCoverage"
                  :color="toggleSettings"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="text-right p-5 pb-0">
        <button class="p-2 px-6 bg-orange-500 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
      <div class="text-center"><small class="text-gray-500">210315</small></div>
    </div>
  </modal>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {CHANGE_SELECTED_LAYER, CHANGE_SHOW_ACTIVE_POSTMEN} from "@/store/modules/map/actions";
import {
  SET_AUTO_REFRESH, SET_AUTO_REFRESH_TIME,
  SET_GROUP_MARKERS,
  SET_SHOW_COVERAGE,
  SET_SHOW_SHIFT_LINES
} from "@/store/modules/map/mutations";
const {mapMutations, mapState, mapActions} = createNamespacedHelpers('map');

export default {
  name: "AppSettingsModal",
  data: () => ({
    toggleSettings: {checked: '#6ad46a', unchecked: '#000000'},
    zoomLevels: [
      {id: 0, name: "Brak", value: "none"},
      {id: 1, name: "Auto / zoom", value: "auto"},
      {id: 2, name: "Main hub/woj", value: "main_hub_id"},
      {id: 3, name: "Small hub/powiaty", value: "small_hub_id"},
      {id: 4, name: "Rejon/gminy", value: "rejon_id"},
      {id: 5, name: "Subrejon/zip codes", value: "sub_rejon_id"},
    ],
  }),
  props: {
    showModal: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      showActivePostmen: state => state.showActivePostmen,
      groupMarkers: state => state.groupMarkers,
      showShiftLines: state => state.showShiftLines,
      showCoverage: state => state.showCoverage,
      selectedLayer: state => state.selectedLayer,
      autoRefresh: state => state.autoRefresh,
    }),
    showActivePostmenToggle() {
      const {date, key} = this.$route.query;
      return !!(date && key);
    },
    showAutoRefreshToggle() {
      return this.$route.query.refresh;
    },
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
  },
  methods: {
    ...mapMutations({
      setGroupMarkers: SET_GROUP_MARKERS,
      setShowShiftLines: SET_SHOW_SHIFT_LINES,
      setShowCoverage: SET_SHOW_COVERAGE,
      setAutoRefresh: SET_AUTO_REFRESH,
      setAutoRefreshTime: SET_AUTO_REFRESH_TIME,
    }),
    ...mapActions({
      changeShowActivePostman: CHANGE_SHOW_ACTIVE_POSTMEN,
      changeSelectedLayer: CHANGE_SELECTED_LAYER,
    }),
    show() {
      this.$modal.show('settings');
    },
    hide() {
      this.$modal.hide('settings');
    },
    handleClose() {
      this.$emit('close-modal');
    },
    handleChaneShowPostmen({value}) {
      this.changeShowActivePostman(value);
      localStorage.setItem('showActivePostmen', value);
    },
    handleChangeGroupMarkers({value}) {
      this.setGroupMarkers(value);
      localStorage.setItem('groupMarkers', value);
    },
    handleChangeShowShiftLines({value}) {
      this.setShowShiftLines(value);
      localStorage.setItem('showShiftLines', value);
    },
    handleShowCoverage({value}) {
      this.setShowCoverage(value);
      localStorage.setItem('showCoverage', value);
    },
    handleChangeAutoRefresh({value}) {
      if (value) this.setAutoRefreshTime(0);
      this.setAutoRefresh(value);
      localStorage.setItem('autoRefresh', value);
    },
    handleSelectLayer(event) {
      this.changeSelectedLayer(event.target.value);
      localStorage.setItem('selectedLayer', event.target.value);
    },
  },
}
</script>

<style scoped>
li {
  margin-top: 10px;
}
</style>