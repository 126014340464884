export const SET_MAP_COORDINATES = 'SET_MAP_COORDINATES';
export const SET_ZOOM = 'SET_ZOOM';
export const SET_POLYGONS = 'SET_POLYGONS';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_ACTIVE_POSTMEN = 'SET_ACTIVE_POSTMEN';
export const SET_DATE_TIME_FOR_ACTIVE_POSTMEN = 'SET_DATE_TIME_FOR_ACTIVE_POSTMEN';
export const SET_SHIFT_POSTMAN = 'SET_SHIFT_POSTMAN';
export const SET_SELECTED_LAYER = 'SET_SELECTED_LAYER';
export const SET_POLYGONS_CANCEL_TOKEN = 'SET_CANCEL_TOKEN_FOR_POLYGONS';
export const SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL = 'SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL';
export const SET_SHOW_SETTINGS_MODAL = 'SET_SHOW_SETTINGS_MODAL';
export const SET_SHOW_SHIFT_POINTS_TABLE = 'SET_SHOW_SHIFT_POINTS_TABLE';
export const SET_SHOW_ACTIVE_POSTMEN = 'SET_SHOW_ACTIVE_POSTMEN';
export const SET_SHOW_SHIFT_LINES = 'SET_SHOW_SHIFT_LINES';
export const SET_PLAY_LINES_STATUS = 'SET_PLAY_LINES_STATUS';
export const SET_PLAY_LINES_STEP = 'SET_PLAY_LINES_STEP';
export const SET_SHOW_COVERAGE = 'SET_SHOW_COVERAGE';
export const SET_GROUP_MARKERS = 'SET_GROUP_MARKERS';
export const SET_AUTO_REFRESH = 'SET_AUTO_REFRESH';
export const SET_AUTO_REFRESH_TIME = 'SET_AUTO_REFRESH_TIME';

export default {
  [SET_MAP_COORDINATES] (state, coordinates) {
    state.mapCoordinates = coordinates;
  },
  [SET_ZOOM] (state, zoom) {
    state.zoom = zoom;
  },
  [SET_POLYGONS] (state, polygons) {
    state.polygons = polygons;
  },
  [SET_LOADING] (state, status) {
    state.loading = status;
  },
  [SET_ERRORS] (state, errors) {
    state.errors = errors;
  },
  [SET_ACTIVE_POSTMEN] (state, postmen) {
    state.activePostmen = postmen;
  },
  [SET_DATE_TIME_FOR_ACTIVE_POSTMEN] (state, dateTime) {
    state.dateTimeForActivePostmen = dateTime;
  },
  [SET_SHIFT_POSTMAN] (state, shiftPostman) {
    if (shiftPostman.scans) {
      state.shiftPostman = shiftPostman.route;
      state.shiftPostmanScans = shiftPostman.scans;
      let nArr = shiftPostman.scans.map(v => ({...v, type: 'scan'}));
      state.shiftPostmanAll = [...shiftPostman.route, ...nArr].sort((a, b) => (a.datetime > b.datetime ? 1 : -1));
    }
    else {
      state.shiftPostmanAll = shiftPostman;
      state.shiftPostman = shiftPostman;
    }
  },
  [SET_SELECTED_LAYER] (state, layer) {
    state.selectedLayer = layer;
  },
  [SET_POLYGONS_CANCEL_TOKEN] (state, token) {
    state.polygonsCancelToken = token;
  },
  [SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL] (state, status) {
    state.showActivePostmenListModal = status;
  },
  [SET_SHOW_SETTINGS_MODAL] (state, status) {
    state.showSettingsModal = status;
  },
  [SET_SHOW_SHIFT_POINTS_TABLE] (state, status) {
    state.showShiftPointsTable = status;
  },
  [SET_SHOW_ACTIVE_POSTMEN] (state, status) {
    state.showActivePostmen = status;
  },
  [SET_SHOW_SHIFT_LINES] (state, status) {
    state.showShiftLines = status;
  },
  [SET_PLAY_LINES_STATUS] (state, status) {
    state.playLinesStatus = status;
  },
  [SET_PLAY_LINES_STEP] (state, step) {
    state.playLinesStep = step;
  },
  [SET_SHOW_COVERAGE] (state, status) {
    state.showCoverage = status;
  },
  [SET_GROUP_MARKERS] (state, status) {
    state.groupMarkers = status;
  },
  [SET_AUTO_REFRESH] (state, status) {
    state.autoRefresh = status;
  },
  [SET_AUTO_REFRESH_TIME] (state, time) {
    state.autoRefreshTime = time;
  },
}