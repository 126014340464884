<template>
  <modal @closed="handleClose" adaptive :max-width="900" :max-height="450" name="shift-point-info">
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col items-center justify-between p-6">
        <div class="info-bloc text-center">
          <h2 class="text-3xl font-bold">{{ point.index }}{{ point.type !== 'scan'? ` / ${numberOfPoints}`: '' }}</h2>
          <ul class="text-xl">
            <li>Listonosz id: <b>{{ point.postman_id }}</b></li>
            <li>Data: <b>{{ point.datetime }}</b></li>
          </ul>
        </div>
      </div>
      <div class="text-right p-5">
        <button class="p-2 px-6 bg-orange-500 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ShiftPointInfoModal",
  props: {
    showModal: {
      type: Boolean,
    },
    point: {
      type: Object,
      required: true,
    },
    numberOfPoints: {
      type: Number,
      required: true,
    }
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    }
  },
  methods: {
    show () {
      this.$modal.show('shift-point-info');
    },
    hide () {
      this.$modal.hide('shift-point-info');
    },
    handleClose() {
      this.$emit('close-modal');
    },
  },
}
</script>

<style scoped>
li {
  margin-top: 15px;
}
</style>