<template>
  <modal
      @closed="handleClose"
      adaptive
      :max-width="900"
      width="700"
      :height="'auto'"
      name="active-postmen-modal"
  >
    <div class="flex flex-col justify-between h-full p-6">
      <div
          class="flex flex-col items-center pt-7"
      >
        <template v-if="postmen.length">
          <h2 class="text-3xl font-bold">
            Aktywni listonosze
          </h2>
          <div style="height: 350px;" class="w-full overflow-auto border-gray-400 rounded border-2">
            <table class="w-full table-postmen">
              <tr class="text-center font-bold">
                <td>Id</td>
                <td>Data</td>
                <td>Zmianę</td>
              </tr>
              <tr v-for="postman in postmen" :key="postman.postman_id" class="text-center">
                <td>{{ postman.postman_id }}</td>
                <td>{{ postman.datetime }}</td>
                <td>
                  <a
                      class="font-bold text-blue-600"
                      target="_blank"
                      rel="noopener noreferrer"
                      :href="shiftLink(postman)"
                  >
                    Pokaż zmianę
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </template>
        <h2 class="text-3xl font-bold" v-else>
          Brak aktywnych listonoszy
        </h2>
      </div>
      <div class="text-right p-3 pb-0">
        <button class="p-2 px-6 bg-orange-500 rounded-xl text-xl font-bold close-btn" @click="handleClose">Zamknij</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ListOfActivePostmenModal",
  props: {
    showModal: {
      type: Boolean,
    },
    postmen: {
      type: Array,
      default: () => ([]),
    },
  },
  watch: {
    showModal(value) {
      value ? this.show() : this.hide();
    },
  },
  methods: {
    shiftLink (postman) {
      return `${process.env.VUE_APP_MAP_URL}/?key=${postman.postman.key}&date=${postman.date}&shiftId=${postman.shift_id}`;
    },
    show() {
      this.$modal.show('active-postmen-modal');
    },
    hide() {
      this.$modal.hide('active-postmen-modal');
    },
    handleClose() {
      this.$emit('close-modal');
    },
  },
}
</script>

<style scoped>
.table-postmen {
  border-collapse: collapse;
}

.table-postmen tr {
  height: 30px;
}

.table-postmen, tr, td {
  border: 1px solid black;
}

</style>