import Vue from 'vue';

const defaultNotifyOptions = {
  duration: 4000,
  group: 'notify',
};

export const notify = ({ type, title, text, duration }) => {
  Vue.notify({
    ...defaultNotifyOptions,
    type,
    title,
    text,
    duration
  });
}